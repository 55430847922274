.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}


.footer {
  background-color: #333;
  color: #fff;
  padding: 5px 0;
  font-family: Arial, sans-serif;
  z-index: 1;
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1500px;
  max-height: 70px;
  margin: 0 auto;
  padding: 15px;
}

nav {
  display: flex;
  margin: 50px 0;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav li {
  margin-right: 15px;
}

nav li:last-child {
  margin-right: 0;
}

nav a {
  text-decoration: none;
  color: #fff;
}

nav a:hover {
  color: #ccc;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.social-icons a {
  color: #fff;
  text-decoration: none;
  margin-right: 10px;
}

.social-icons a:last-child {
  margin-right: 0;
}

.social-icons a:hover {
  color: #ccc;
}

.newsletter-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.newsletter-form label {
  margin-bottom: 5px;
}

.newsletter-form input[type="email"] {
  padding: 5px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  width: 100%;
  max-width: 300px;
  margin-bottom: 10px;
}

.newsletter-form button[type="submit"] {
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  background-color: #555;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s;
}

.newsletter-form button[type="submit"]:hover {
  background-color: #777;
}

@media (min-width: 768px) {
  .container {
    flex-direction: row;
    justify-content: space-between;
  }

  nav {
    margin: 0;
  }
}

.download-app {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
}

.app-badge {
  width: 120px;
  display: inline;
  margin: 5px;
  flex-direction: row;
  /* align-items: center; */
}

.app-badge2 {
  width: 110px;
  margin: 9px;
  flex-direction: row;
  /* align-items: center; */
}

.app-text {
  margin-left: 50px;
}